.gradient__primary-secondary {
    background: linear-gradient(0deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    --background: linear-gradient(0deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    color: #ffffff;
    border: none;
}
.gradient__primary-secondary-45 {
    background: linear-gradient(45deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    --background: linear-gradient(45deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    color: #ffffff;
    border: none;
}
.gradient__primary-secondary-90 {
    background: linear-gradient(90deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    --background: linear-gradient(90deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    color: #ffffff;
    border: none;
}
.gradient__primary-secondary-135 {
    background: linear-gradient(135deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    --background: linear-gradient(135deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    color: #ffffff;
    border: none;
}
.gradient__primary-secondary-180 {
    background: linear-gradient(180deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    --background: linear-gradient(180deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    color: #ffffff;
    border: none;
}
.gradient__primary-secondary-225 {
    background: linear-gradient(225deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    --background: linear-gradient(225deg, rgba(var(--ion-color-primary-rgb),1) 0%, rgba(var(--ion-color-secondary-rgb),0.80) 100%);
    color: #ffffff;
    border: none;
}

.grandient__anim {
    background: linear-gradient(to right, #8e2de2, #4a00e0);
    --background: linear-gradient(to right, #8e2de2, #4a00e0);
    color: #ffffff;
    border: none;
}