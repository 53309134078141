@font-face {
    font-family: 'robotobold';
    src: url('../assets/fonts/Roboto-Bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'robotolight';
    src: url('../assets/fonts/Roboto-Light-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    src: url('../assets/fonts/Roboto-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotothin';
    src: url('../assets/fonts/Roboto-Thin-webfont.woff') format('woff');
    font-weight: thin;
    font-style: normal;
}
