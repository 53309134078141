:root {
    --header-height: 94px;
}

* {
    font-family: 'robotoregular';
}
html.ios {
    --ion-default-font: 'robotoregular';
}
html.md {
    --ion-default-font: 'robotoregular';
}

ion-content {
    background: linear-gradient(to top, #956bba, #2d077e);
    --background: linear-gradient(to top, #956bba, #2d077e);
}

ion-content.background {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    --background: none;
    -webkit-background-position: center center;
    -moz-background-position: center center;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}
ion-content.background.with-field::after {
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background: url('/assets/images/field.png') no-repeat;
    -webkit-background-position: bottom center;
    -moz-background-position: bottom center;
    background-position: bottom center;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
}

.grid-full-screen {
    width: 100%;
    height: 100%;
}

.page__heading {
    width: 100%;
    padding: 10px 5px;
    border-radius: 15px;
    background: rgba(var(--ion-color-primary-rgb), 0.7);
    color: var(--ion-color-primary-contrast);
}

.page__heading-title {
    width: 100%;
    color: var(--ion-color-secondary);
}

.page__heading-subtitle {
    width: 100%;
    color: var(--ion-color-primary-contrast);
}

.spinner-lines.sc-ion-loading-md,
.spinner-lines-small.sc-ion-loading-md,
.spinner-bubbles.sc-ion-loading-md,
.spinner-circles.sc-ion-loading-md,
.spinner-crescent.sc-ion-loading-md,
.spinner-dots.sc-ion-loading-md {
    --spinner-color: var(--ion-color-secondary);
}

/* Vote Modal */
.vote-modal .modal-wrapper {
    background: rgba(var(--ion-color-primary-rgb), 0.7);
}
.vote-modal .modal-wrapper ion-content {
    --background: none;
}
.vote-modal.vote-modal-with-background {
    --background: rgba(0, 0, 0, 0.9);
}
.modal-default {
    --background: #222;
}

.spacer {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
}
.spacer-10 {
    @extend .spacer;
    height: 10px;
}
.spacer-20 {
    @extend .spacer;
    height: 20px;
}
.spacer-30 {
    @extend .spacer;
    height: 30px;
}
.spacer-40 {
    @extend .spacer;
    height: 40px;
}
.spacer-50 {
    @extend .spacer;
    height: 50px;
}
.spacer-100 {
    @extend .spacer;
    height: 100px;
}
.spacer-150 {
    @extend .spacer;
    height: 150px;
}
.spacer-250 {
    @extend .spacer;
    height: 250px;
}
.spacer-500 {
    @extend .spacer;
    height: 500px;
}

.alert-button.sc-ion-alert-md {
    color: var(--ion-color-secondary);
}

.sc-ion-loading-md-h {
    color: var(--ion-color-secondary);
}

ion-button {
    min-height: 24px;
}